.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background: url('') no-repeat center center fixed;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.7);
}

.login-card {
  width: 100%;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
}
.links-container {
  display: flex;
  justify-content: space-between;
}

.icon-container {
  display: flex;
  justify-content: center;
}