*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body, #root{
  height: 100%;
}

body{
  font: 14px;
  font-family: sans-serif;
  background-color: #EFEFEF;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
}

button{
  cursor: pointer;
}